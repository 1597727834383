import styled from 'react-emotion'
import { rem, darken } from 'polished'
import Link from './Link'
import theme, { media } from '../styles/theme'

export const Notice = styled(Link)`
  padding: 10px 30px;
  background-color: ${theme.colors.noticeBackground};
  color: ${theme.colors.noticeCopy};
  font-size: ${rem('15px')};
  font-weight: bold;
  letter-spacing: ${rem('2.25px')};
  line-height: ${rem('26px')};
  width: 100%;
  text-align: center;
  display: block;

  ${media.greaterThan('small')`
    font-size: ${rem('18px')};
    padding: 15px 30px;
  `};

  &:hover {
    background-color: ${darken(0.075, theme.colors.noticeBackground)};
  }
`

export default Notice
