import styled from 'react-emotion'
import { rem } from 'polished'
import Link from './Link'

export const Button = styled(Link)`
  background-color: #003b4d;
  color: #fff;
  font-size: ${rem('14px')};
  font-weight: 600;
  letter-spacing: ${rem('1.56px')};
  line-height: ${rem('18px')};
  text-align: center;
  text-transform: uppercase;
  padding: ${rem('10px')} ${rem('18px')};
  display: inline-block;
`

export default Button
