import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'

export default ({ data }) => {
  const { site } = data
  return (
    <Layout>
      <Helmet>
        <title>Page Not Found - {site.siteMetadata.title}</title>
      </Helmet>
      <h1>Page Not Found</h1>
    </Layout>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`
