// @flow

import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { css } from 'react-emotion'
import { injectGlobal } from 'emotion'
import { StaticQuery, graphql } from 'gatsby'
import globalStyles from '../styles/global.css'
import Footer from './Footer'
import Header from './Header'
import Nav from './Nav'
import Notice from './Notice'

injectGlobal`${globalStyles}`

const announcement = data =>
  typeof window !== 'undefined' &&
  window.location.pathname === '/' &&
  data.homepage.data.showAnnouncement === 'Yes' && (
    <Notice to={data.homepage.data.announcementLink.url}>
      {' '}
      {data.homepage.data.announcementText}{' '}
    </Notice>
  )

const helmet = data => <Helmet title={data.site.siteMetadata.title} />

type LayoutProps = {
  children?: typeof React.Children,
  data?: Object,
}

const Layout = ({ children, data }: LayoutProps) => (
  <>
    {helmet(data)} {announcement(data)}{' '}
    <div
      className={css`
        position: relative;
      `}>
      <Header links={data.navigation.data.links} />{' '}
      <div className="main-content"> {children} </div>{' '}
      <Footer html={data.footer.data.body.html} />{' '}
    </div>{' '}
  </>
)

const query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    footer: prismicFooter {
      ...footerFields
    }
    navigation: prismicNavigation {
      ...navigationFields
    }
    homepage: prismicHome {
      data {
        announcementText: announcement_text
        announcementLink: announcement_link {
          url
        }
        showAnnouncement: show_announcement
      }
    }
  }
`

type LayoutComponentProps = {
  props?: Object,
  data?: Object,
}

export default (props: LayoutComponentProps) => (
  <StaticQuery
    query={query}
    render={data => <Layout data={data} {...props} />}
  />
)
