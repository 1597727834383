import styled, { css } from 'react-emotion'
import { rem } from 'polished'
import theme, { media } from '../styles/theme'

export const LayoutContainerStyle = css`
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;

  ${media.greaterThan('small')`
    padding-left: 30px;
    padding-right: 30px;
  `};
`

export const LayoutContainer = styled.div`
  max-width: ${theme.dimensions.layoutMaxWidth};
  ${LayoutContainerStyle};

  ${p =>
    p.center &&
    css`
      text-align: center;
    `};
`

export const SplitLayoutContainerStyle = css`
  ${media.greaterThan('medium')`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
  `};
`

export const SplitLayoutContainer = styled(LayoutContainer)`
  ${SplitLayoutContainerStyle};
`

export const ConstrainedContentStyle = css`
  max-width: 590px;
  margin: 0 auto;
`

export const ConstrainedContent = styled.div`
  ${ConstrainedContentStyle};
`

export default LayoutContainer
