import React from 'react'
import styled, { css } from 'react-emotion'
import { rem } from 'polished'
import Nav from './Nav'
import Link from './Link'
import { media } from '../styles/theme'
import LogoFirstSmiles from '../assets/images/svgr/LogoFirstSmiles'

const HeaderStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 20px;
  z-index: 2;
  flex-direction: column;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25), transparent);

  ${media.greaterThan('small')`
    padding: ${rem('20px')} ${rem('50px')};
    height: 100px;
    flex-direction: row;
  `};
`

const LogoLink = styled(Link)`
  display: inline-block;
`

type HeaderProps = {
  links: Array,
}

export const Header = ({ links }: HeaderProps) => (
  <header
    className={css`
      ${HeaderStyles};
    `}>
    <LogoLink to="/">
      <LogoFirstSmiles />
    </LogoLink>
    <Nav links={links} />
  </header>
)

export default Header
