const colors = {
  darkBlue: '#003b4d',
  gray: '#4a4a4a',
  green: '#b7d866',
  darkGreen: '#66763f',
  grayMedium: '#525252',
}
import { generateMedia } from '@engineerapart/emotion-media-query'

export const media = generateMedia({
  large: '1200px',
  mediumLarge: '1024px',
  medium: '960px',
  small: '675px',
  xSmall: '420px',
})

export const theme = {
  colors: {
    links: colors.green,
    bodyCopy: colors.darkBlue,
    secondaryCopy: colors.gray,
    generalHeading: colors.darkBlue,
    generalCopy: colors.gray,
    footerBackground: colors.darkBlue,
    introRule: colors.green,
    listBullet: colors.green,
    teamMemberName: colors.darkBlue,
    teamMemberTitle: colors.grayMedium,
    question: colors.darkBlue,
    answer: colors.gray,
    noticeBackground: colors.green,
    noticeCopy: colors.darkGreen,
  },
  dimensions: {
    layoutMaxWidth: '993px',
  },
  fonts: {
    default: '"Montserrat", sans-serif',
  },
}

export default theme
