import React from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'react-emotion'
import { rem } from 'polished'
import { media } from '../styles/theme'
import Link from './Link'
import Button from './Button'

const NavStyles = css`
  display: flex;
  margin-top: 20px;
  justify-content: center;
  align-items: center;

  ${media.greaterThan('small')`
    margin-top: 0;
    justify-content: flex-end;
  `};
`

const NavLinkStyles = css`
  margin: 0 10px;

  &:hover {
    color: #ddd;
  }

  &:active {
    color: #ccc;
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
`

export const NavLink = styled(Link)`
  ${NavLinkStyles};
  color: #fff;
  font-size: ${rem('14px')};
  font-weight: 600;
  letter-spacing: ${rem('1.56px')};
  line-height: ${rem('18px')};
  text-transform: uppercase;

  ${media.greaterThan('small')`
    margin-right: 30px;
  `};
`

export const NavLinkButton = styled(Button)`
  ${NavLinkStyles};
  display: inline-block;

  ${media.lessThan('small')`
    background: transparent;
    padding: 0;
  `};
`

type NavProps = {
  links: Array,
}

export const Nav = ({ links }: NavProps) => (
  <nav
    className={css`
      ${NavStyles};
    `}>
    {!!links.length &&
      links.map(
        link =>
          link.type === 'button' ? (
            <NavLinkButton key={link.label} to={link.link.url}>
              {link.label}
            </NavLinkButton>
          ) : (
            <NavLink key={link.label} to={link.link.url}>
              {link.label}
            </NavLink>
          )
      )}
  </nav>
)

export default Nav

export const query = graphql`
  fragment navigationFields on PrismicNavigation {
    data {
      links {
        label
        type
        link {
          url
        }
      }
    }
  }
`
