import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'react-emotion'
import { rem } from 'polished'
import theme, { media } from '../styles/theme'
import LogoFirstSmiles from '../assets/images/svgr/LogoFirstSmiles'
import LayoutContainer from './LayoutContainer'
import Link from './Link'

const FooterContent = styled.p`
  font-size: ${rem('14px')};
  font-weight: 500;
  line-height: ${rem('24px')};
  text-align: center;

  a {
    text-decoration: none;
    color: #fff;
  }
`

type FooterProps = {
  html?: String,
}

const Footer = ({ html }: FooterProps) => (
  <footer
    className={css`
      color: #fff;
      background: ${theme.colors.footerBackground};
      border-top: solid 17px #b7d866;
      text-align: center;
      padding-bottom: 40px;

      ${media.greaterThan('small')`
        min-height: 350px;
      `};
    `}>
    <LayoutContainer>
      <LogoFirstSmiles
        className={css`
          margin: ${rem('30px')} 0 ${rem('10px')};
        `}
      />
      <FooterContent dangerouslySetInnerHTML={{ __html: html }} />
    </LayoutContainer>
  </footer>
)

export default Footer

export const query = graphql`
  fragment footerFields on PrismicFooter {
    data {
      body {
        html
      }
    }
  }
`
